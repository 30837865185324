<template>
  <div class="c-table">
    <div class="inner">
      <div class="profile-logs">
        <h2 class="profile-logs__title">{{ $t("logs.title") }}</h2>

        <vue-good-table style-class="table _no-wrap" :columns="columns" :rows="logs">
          <template slot="table-column" slot-scope="props">
            <span> {{ $t(props.column.label) }}: </span>
          </template>

          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'action_code'">
              <span>{{ $t(`logs.table.${props.row.action_code}`) }}</span>
            </template>

            <template v-else-if="props.column.field === 'status'">
              <span v-if="props.row.status === 200" class="successfully">
                <svg v-svg symbol="success-bold" width="17" height="17" role="info"></svg>
                {{ $t(`logs.table.successfully`) }}</span
              >
              <span v-else class="not-successfully">
                <svg v-svg symbol="not-success-bold" size="0 0 17 17" role="info"></svg>
                {{ $t(`logs.table.not_successfully`) }}
              </span>
            </template>
          </template>

          <div class="d-flex justify-content-center" slot="emptystate">
            <template v-if="!loadDataTable">
              {{ $t("any_results") }}
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <img src="@/assets/images/rolling.svg" alt="loader" class="loader" />
              </div>
            </template>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import PanelService from "@/api/servicePanel";

export default {
  name: "ProfileLogs",
  data() {
    return {
      columns: [
        {
          label: "logs.table.date",
          field: "date",
          formatFn: (value) => {
            return value.replace(/-/g, ".");
          },
          sortable: false,
        },
        { label: "logs.table.time", field: "time", sortable: false },
        {
          label: "logs.table.action",
          field: "action_code",
          sortable: false,
        },
        {
          label: "logs.table.status",
          field: "status",
          sortable: true,
        },
      ],
      loadDataTable: false,
      logs: [],
    };
  },
  methods: {
    getLogList() {
      this.loadDataTable = true;
      this.logs = [];

      PanelService.getLogList()
        .then((res) => {
          if (res && res.status === 200) {
            this.logs = res.data?.logs;
          }
        })
        .finally(() => {
          this.loadDataTable = false;
        });
    },
  },
  mounted() {
    this.getLogList();
  },
};
</script>

<style lang="scss" scoped>
.profile-logs {
  width: 100%;
  margin-top: 40px;

  &__title {
    font-size: 24px;
    line-height: 36px;
    text-transform: none;
    padding: 0;
  }

  span {
    font-weight: 600;
    line-height: 20px;
  }

  .successfully {
    white-space: nowrap;
    color: #37807d;

    svg {
      fill: #37807d;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }

  .not-successfully {
    white-space: nowrap;
    color: #c62424;

    svg {
      fill: #c62424;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }
}

::v-deep .table td,
::v-deep .table th span {
  font-weight: 400;
}

::v-deep .table th.sortable button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
  outline: 0;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
